<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <feather-icon
          @click="toggleVerticalMenuActive"
          icon="MenuIcon"
          size="21"
        />
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{operatorName()}}
            </p>
            <span class="user-status">
              {{operatorRole()}}
            </span>
          </div>
        </template>

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, Blink,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods:{
    operatorName(){
      return JSON.parse(window.localStorage.getItem('userData')).fullName
    },
    operatorRole(){
      let roleTrans = {
        ['chat_attendant'] : 'Atendimento',
        ['sales'] : 'Comercial',
        ['moderator'] : 'Moderador',
        ['master'] : 'Parceiro',
        ['admin'] : 'Administrador',
        ['master'] : 'Op. Master',
      }
      return roleTrans[JSON.parse(window.localStorage.getItem('userData')).userRole]
    },
    logout(){
      window.localStorage.removeItem('userData')
      this.$router.push('/login')
    }
  }
}
</script>
