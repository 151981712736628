export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth'
  },
  {
    title: 'Clientes',
    icon: 'UserIcon',
    children:[
      { 
        title: 'Listar',
        icon: 'ListIcon',
        route: 'clients-list',
        action: 'be',
        resource: 'company-employee'
      },
      // { 
      //   title: 'Cadastrados Hoje',
      //   icon: 'ListIcon',
      // },
      { 
        title: 'Grupos',
        icon: 'ListIcon',
        children:[
          { 
            title: 'Listar',
            icon: 'ListIcon',
            route: 'user-groups-list',
            action: 'read',
            resource: 'client-groups'
          },
          { 
            title: 'Criar',
            icon: 'ListIcon',
            route: 'user-groups-create',
            action: 'edit',
            resource: 'client-groups'
          },
        ]
      },
      // { 
      //   title: 'Bloqueados',
      //   icon: 'ListIcon',
      // },
    ]
  },
  {
    title: 'Transações',
    icon: 'DollarSignIcon',
    children:[
      // { 
      //   title: 'Listar Depositos',
      //   icon: 'ListIcon',
      // },
      { 
        title: 'Listar Saques',
        icon: 'DollarSignIcon',
        route: 'withdraws-list'

      },
      { 
        title: 'Listar Depositos',
        icon: 'DollarSignIcon',
        route: 'deposits-list'
      },
      { 
        title: 'Consolidado',
        icon: 'DollarSignIcon',
        route: 'consolidated'
      },
      { 
        title: 'GGR Consolidado',
        icon: 'DollarSignIcon',
        route: 'ggr-consolidated'
      },
      // { 
      //   title: 'Listar Bonus',
      //   icon: 'ListIcon',
      // },
      // { 
      //   title: 'Listar Pendentes',
      //   icon: 'ListIcon',
      // },
    ]
  },
  {
    title: 'Promoçoes',
    icon: 'MessageCircleIcon',
    children:[
      { 
        title: 'Cadastrar',
        icon: 'ListIcon',
        route: 'promotion-create',
        action: 'edit',
        resource: 'client-promotions'
      },
      { 
        title: 'Listar Ativas',
        icon: 'ListIcon',
        route: 'user-promotions-list',
        action: 'read',
        resource: 'client-promotions'
      },
      { 
        title: 'Brincadeiras',
        icon: 'ListIcon',
        route: 'users-playtime',
        action: 'read',
        resource: 'client-promotions'
      },
      // { 
      //   title: 'Listar Todas',
      //   icon: 'ListIcon',
      // }
    ]
  },
  {
    title: 'Menu Master',
    icon: 'UserIcon',
    children:[
      { 
        title: 'Listar Clientes',
        icon: 'ListIcon',
        route: 'master-clients-list',
        action: 'be',
        resource: 'company-partner'
      },
      { 
        title: 'Listar Transações',
        icon: 'DollarSignIcon',
        route: 'master-transactions',
        action: 'be',
        resource: 'company-partner'
      },
      // { 
      //   title: 'Bloqueados',
      //   icon: 'ListIcon',
      // },
    ]
  },
  {
    title: 'Jogos',
    icon: 'PlayIcon',
    children:[
      { 
        title: 'Listar',
        icon: 'ListIcon',
        route: 'games-list',
        action: 'edit',
      },
      // { 
      //   title: 'Cadastrados Hoje',
      //   icon: 'ListIcon',
      // },
      { 
        title: 'Grupos',
        icon: 'ListIcon',
        children:[
          { 
            title: 'Listar',
            icon: 'ListIcon',
            route: 'games-group-list',
            action: 'edit',
          },
          { 
            title: 'Criar',
            icon: 'ListIcon',
            route: 'user-groups-create',
            action: 'edit',
            resource: 'client-groups'
          },
        ]
      },
      // { 
      //   title: 'Bloqueados',
      //   icon: 'ListIcon',
      // },
    ]
  },
  
  // {
  //   title: 'Afiliados',
  //   icon: 'UsersIcon',
  //   children:[
  //     { 
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Relatórios',
  //   icon: 'TrendingUpIcon',
  //   children:[
  //     { 
  //       title: 'Movimento de jogos',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Entradas e saídas',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Usuários Adm',
  //   icon: 'UserCheckIcon',
  //   children:[
  //     { 
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Funcionárops',
  //   icon: 'BriefcaseIcon',
  //   children:[
  //     { 
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     { 
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },

  // {
  //   title: 'Roulette',
  //   icon: 'UserIcon',
  //   children:[
  //     { 
  //       title: 'Rondas',
  //       icon: 'ListIcon',
  //       route: 'rounds-list',
  //       action: 'be',
  //       resource: 'company-employee'
  //     },
  //   ]
  // },

  // {
  //   title: 'Chat',
  //   icon: 'UserIcon',
  //   children:[
  //     { 
  //       title: 'Rooms',
  //       icon: 'ListIcon',
  //       route: 'rooms-list',
  //       action: 'be',
  //       resource: 'company-employee'
  //     }
  //   ]
  // },
]
